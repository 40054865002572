<template>
  <div>
    <v-card class="pa-3">
      <h2>Oops!</h2>
      This page doesn't seem to exist. Sorry about that.<br />You can try going
      <a @click="$router.back()">back</a> to the previous page.
    </v-card>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>
